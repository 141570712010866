import { useState } from "react";
import "./App.css";
import DatabaseConnectionForm, {
  fetchDatabaseInfo,
} from "./DatabaseConnectionForm";
import ColumnSelector, { fetchReport } from "./DynamicTableHeader";
import { Box, Container, Typography } from "@mui/material";

function App() {
  const [tableInfo, setTableInfo] = useState(null);
  const [reportInfo, setReportInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [connectionDetails, setConnectionDetails] = useState(null);

  const handleConnect = async (newConnectionDetails) => {
    setIsLoading(true);
    setConnectionDetails(newConnectionDetails);
    try {
      const data = await fetchDatabaseInfo(newConnectionDetails);
      setTableInfo(data);
    } catch (error) {
      console.error("Error fetching database info:", error);
      // כאן תוכל להוסיף טיפול בשגיאות, כמו הצגת הודעה למשתמש
    } finally {
      setIsLoading(false);
    }
  };
  const onCreateReport = async (reportDetails) => {
    setIsLoading(true);
    try {
      const data = await fetchReport(connectionDetails, reportDetails);
      setReportInfo(data);
    } catch (error) {
      console.error("Error fetching report info:", error);
      // כאן תוכל להוסיף טיפול בשגיאות, כמו הצגת הודעה למשתמש
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          מערכת דוחות דינמית
        </Typography>
        <DatabaseConnectionForm onConnect={handleConnect} />
        {(isLoading || tableInfo) && (
          <ColumnSelector
            tableInfo={tableInfo}
            reportInfo={reportInfo}
            isLoading={isLoading}
            onCreateReport={onCreateReport}
          />
        )}
      </Box>
    </Container>
  );
}

export default App;
