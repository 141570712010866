import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Select,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 200,
  maxWidth: 300,
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ScrollableMenuList = styled(MenuList)({
  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  overflowY: "auto",
});

const NestedMenu = ({
  table,
  tableInfo,
  reportInfo,
  onSelect,
  anchorEl,
  onClose,
  isSubsequentSelector,
  path = [],
}) => {
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleMouseEnter = (item, event) => {
    if (
      item.foreign_table_name ||
      (table.referenced_by_tables &&
        table.referenced_by_tables.includes(item.name))
    ) {
      setOpenSubMenu({ item, anchor: event.currentTarget });
    }
  };

  const handleMouseLeave = () => {
    setOpenSubMenu(null);
  };

  const handleClick = (tableName, columnName, event) => {
    event.stopPropagation();
    onSelect(tableName, columnName, [
      ...path,
      { table: tableName, column: columnName },
    ]);
    onClose();
  };

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="right-start"
      style={{ zIndex: 1301 }}
    >
      <ClickAwayListener onClickAway={() => {}}>
        <Paper elevation={3}>
          <ScrollableMenuList>
            {table.columns.map((column) => (
              <ListItem
                key={column.name}
                onMouseEnter={(event) => handleMouseEnter(column, event)}
                onMouseLeave={handleMouseLeave}
                onClick={(event) => handleClick(table.name, column.name, event)}
                button
              >
                <ListItemText primary={column.name} />
                {openSubMenu &&
                  openSubMenu.item === column &&
                  column.foreign_table_name && (
                    <NestedMenu
                      table={tableInfo.find(
                        (t) => t.name === column.foreign_table_name
                      )}
                      tableInfo={tableInfo}
                      onSelect={onSelect}
                      anchorEl={openSubMenu.anchor}
                      onClose={onClose}
                      isSubsequentSelector={isSubsequentSelector}
                      path={[
                        ...path,
                        { table: table.name, column: column.name },
                      ]}
                    />
                  )}
              </ListItem>
            ))}
            {table.referenced_by_tables &&
              table.referenced_by_tables.map((refTable) => (
                <ListItem
                  key={refTable}
                  onMouseEnter={(event) =>
                    handleMouseEnter({ name: refTable }, event)
                  }
                  onMouseLeave={handleMouseLeave}
                  onClick={(event) => handleClick(refTable, "", event)}
                  button
                >
                  <ListItemText primary={`${refTable} (Referenced)`} />
                  {openSubMenu && openSubMenu.item.name === refTable && (
                    <NestedMenu
                      table={tableInfo.find((t) => t.name === refTable)}
                      tableInfo={tableInfo}
                      onSelect={onSelect}
                      anchorEl={openSubMenu.anchor}
                      onClose={onClose}
                      isSubsequentSelector={isSubsequentSelector}
                      path={[...path, { table: table.name, column: refTable }]}
                    />
                  )}
                </ListItem>
              ))}
          </ScrollableMenuList>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

const ColumnSelector = ({
  tableInfo,
  reportInfo,
  isLoading,
  onCreateReport,
}) => {
  const [selectedColumns, setSelectedColumns] = useState([
    { tableName: "", columnName: "", operation: "group", path: [] },
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);

  useEffect(() => {
    if (
      selectedColumns[selectedColumns.length - 1].columnName &&
      selectedColumns.length < tableInfo.length
    ) {
      setSelectedColumns((prev) => [
        ...prev,
        {
          tableName: prev[0].tableName,
          columnName: "",
          operation: "group",
          path: [],
        },
      ]);
    }
  }, [selectedColumns, tableInfo]);

  const handleMouseEnter = (index, tableName, event) => {
    setAnchorEl({ element: event.currentTarget, tableName, index });
    setOpenIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenIndex(null);
  };

  const handleSelect = (tableName, columnName, path) => {
    setSelectedColumns((prev) => {
      const newColumns = [...prev];
      newColumns[anchorEl.index] = {
        ...newColumns[anchorEl.index],
        tableName,
        columnName,
        path,
      };
      return newColumns;
    });
    handleClose();
  };

  const handleOperationChange = (index, operation) => {
    setSelectedColumns((prev) => {
      const newColumns = [...prev];
      newColumns[index] = { ...newColumns[index], operation };
      return newColumns;
    });
  };

  const handleCreateReport = () => {
    if (onCreateReport) {
      onCreateReport(selectedColumns);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" m={2}>
        <CircularProgress />
      </Box>
    );
  }

  const getRelevantTables = (index) => {
    if (index === 0) {
      return tableInfo;
    } else {
      const firstSelectedTable = tableInfo.find(
        (t) => t.name === selectedColumns[0].tableName
      );
      return firstSelectedTable ? [firstSelectedTable] : [];
    }
  };

  return (
    <Box m={2}>
      <Typography variant="h6" gutterBottom>
        בחר טבלה ועמודות
      </Typography>
      <Grid container spacing={2}>
        {selectedColumns.map((selected, index) => (
          <Grid item key={index}>
            <StyledFormControl>
              <InputLabel>{`בחירה ${index + 1}`}</InputLabel>
              <Select
                value={
                  selected.columnName
                    ? `${selected.tableName}.${selected.columnName}`
                    : ""
                }
                renderValue={(selected) => selected || `בחר עמודה ${index + 1}`}
                onOpen={(event) =>
                  handleMouseEnter(index, selected.tableName, event)
                }
                onClose={handleClose}
                open={openIndex === index}
                MenuProps={MenuProps}
              >
                {getRelevantTables(index).map((table) => (
                  <StyledMenuItem
                    key={table.name}
                    value={table.name}
                    onMouseEnter={(event) =>
                      handleMouseEnter(index, table.name, event)
                    }
                  >
                    {index === 0 ? table.name : ""}
                    {openIndex === index &&
                      anchorEl &&
                      anchorEl.tableName === table.name &&
                      anchorEl.index === index && (
                        <NestedMenu
                          table={table}
                          tableInfo={tableInfo}
                          onSelect={handleSelect}
                          anchorEl={anchorEl.element}
                          onClose={handleClose}
                          isSubsequentSelector={index !== 0}
                          path={[]}
                        />
                      )}
                  </StyledMenuItem>
                ))}
              </Select>
            </StyledFormControl>
            {selected.columnName && (
              <RadioGroup
                row
                value={selected.operation}
                onChange={(e) => handleOperationChange(index, e.target.value)}
              >
                <FormControlLabel
                  value="group"
                  control={<Radio />}
                  label="קיבוץ"
                />
                <FormControlLabel
                  value="count"
                  control={<Radio />}
                  label="ספירה"
                />
                <FormControlLabel
                  value="sum"
                  control={<Radio />}
                  label="סכום"
                />
              </RadioGroup>
            )}
          </Grid>
        ))}
      </Grid>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateReport}
        >
          צור דו"ח
        </Button>
      </Box>
      {reportInfo && (
        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            תוצאות הדו"ח
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(reportInfo[0]).map((key) => (
                    <TableCell key={key}>{key}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportInfo.map((row, index) => (
                  <TableRow key={index}>
                    {Object.values(row).map((value, cellIndex) => (
                      <TableCell key={cellIndex}>{value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default ColumnSelector;
export const fetchReport = async (connectionDetails, reportDetails) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/report`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        connectionInfo: connectionDetails,
        reportInfos: reportDetails,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching database info:", error);
  }
};
