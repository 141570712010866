import React, { useState } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const DatabaseConnectionForm = ({ onConnect }) => {
  const [connectionDetails, setConnectionDetails] = useState({
    type: "postgres",
    host: "",
    port: "",
    username: "",
    password: "",
    database: "",
    useSsl: false,
  });

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setConnectionDetails({
      ...connectionDetails,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onConnect(connectionDetails);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>סוג דאטהבייס</InputLabel>
            <Select
              name="type"
              value={connectionDetails.type}
              onChange={handleChange}
              label="סוג דאטהבייס"
            >
              <MenuItem value="postgres">PostgreSQL</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="host"
            label="Host"
            value={connectionDetails.host}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="port"
            label="Port"
            type="number"
            value={connectionDetails.port}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="username"
            label="Username"
            value={connectionDetails.username}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="password"
            label="Password"
            type="password"
            value={connectionDetails.password}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="database"
            label="Database"
            value={connectionDetails.database}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={connectionDetails.useSsl}
                onChange={handleChange}
                name="useSsl"
              />
            }
            label="השתמש ב-SSL"
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            התחבר וקבל מידע
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DatabaseConnectionForm;
export const fetchDatabaseInfo = async (connectionDetails) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/database-info`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(connectionDetails),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching database info:", error);
  }
};
